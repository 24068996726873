export function language(document) { return { language: document.language } }
export function slug(document) { return { slug: document.slug?.current } }

/**
 * Be aware, if any of the extractors is async, this will return a promise
 */
export function extract(...extractors) {
  return (...args) => extractors.reduce(
    (result, extract) => {
      return thenable(result)
        .then(result => {
          const extracted = thenable(extract(...args))

          return extracted.then(extracted => ({ ...result, ...extracted }))
        })
    },
    {}
  )
}

function thenable(x) {
  return x.then
    ? x
    : { then(f) { return f(x) } }
}
