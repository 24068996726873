import { OK, INTERNAL_SERVER_ERROR, NOT_FOUND } from './statusCodes'
import { routeSymbol } from '@kaliber/routing'

export async function determineDocumentPath({ body }, { routeMap, client, reportError }) {
  const document = body
  try {
    const path = await determineDocumentPathAsync({ document, routeMap, client })
    return { status: OK, body: { path } }
  } catch (e) {
    const code = e.code || INTERNAL_SERVER_ERROR
    const isUnexpected = code === INTERNAL_SERVER_ERROR
    if (isUnexpected) reportError(e)

    return {
      status: code,
      body: isUnexpected
        ? { error: process.env.NODE_ENV === 'production' ? 'Internal server error' : e.stack }
        : { message: e.message, document }
    }
  }
}

export async function determineDocumentPathAsync({ document, routeMap, client }) {
  const { route, params } = await determineRouteAndParamsOfDocument({ document, routeMap, client })
  return executeReverseRouting({ route, params, document })
}

export function determineDocumentPathSync({ document, routeMap }) {
  const result = determineRouteAndParamsOfDocument({ document, routeMap, client: undefined })
  if (result.then) throw new ResolveError(INTERNAL_SERVER_ERROR, `Parameter extractor found for type '${document._type}' is async, this method does not support async extractors`)

  const { route, params } = result
  return executeReverseRouting({ route, params, document })
}

function determineRouteAndParamsOfDocument({ document, routeMap, client }) {
  if (!document) throw new ResolveError(NOT_FOUND, `No document was provided`)
  if (!document._type) throw new ResolveError(NOT_FOUND, `The given document does not have a _type:\n${JSON.stringify(document)}`)

  const found = getExtractorAndRoute({ routeMap, type: document._type })
  if (!found) throw new ResolveError(NOT_FOUND, `Could not find type '${document._type}' in extractParams of route map for document:\n${JSON.stringify(document)}`)

  const { route, extractParams } = found
  const params = extractParams(document, { client })

  if (!params.then) return { route, params }
  else return params.then(params => ({ route, params }))
}

function executeReverseRouting({ route, params, document }) {
  try {
    return route(params)
  } catch (e) {
    throw new ResolveError(
      INTERNAL_SERVER_ERROR,
      `Failed to construct reverse route.\n` +
      `Document: ${JSON.stringify(document)}\n` +
      `Extracted params: ${JSON.stringify(params)}\n` +
      `Route: ${route.toString()}\n` +
      `Error: ${e}`
    )
  }
}

class ResolveError extends Error {
  constructor(status, message) {
    super(message)
    this.code = status
  }
}

function getExtractorAndRoute({ routeMap, type }) {
  if (!getExtractorAndRoute['cachedResult']) {
    getExtractorAndRoute['cachedResult'] = Object.fromEntries(getRouteAndExtractParams(routeMap.app))
  }
  return getExtractorAndRoute['cachedResult']?.[type]
}

function getRouteAndExtractParams(route) {
  if (!route || (typeof route !== 'object' && typeof route !== 'function') )
    return []

  const childInfo = getRouteAndExtractParamsOfChildren(route)
  const extractParams = route.data?.extractParams

  if(!extractParams) return childInfo
  
  const types = Object.entries(extractParams).map(([k, v]) => [k, { extractParams: v, route }] )

  return types.concat(childInfo)
}

function getRouteAndExtractParamsOfChildren(route) {
  return Object.values(route[routeSymbol].children).flatMap(getRouteAndExtractParams)
}
