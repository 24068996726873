export function docWithSeo(doc, fallback) {
  return {
    ...doc,
    seo: deepMergeValueIfNullish(doc.seo, fallback)
  }
}

// Skips objects with _type property other than 'seo': those are Sanity
// documents/objects which should be considered atomic (a single entity)
function deepMergeValueIfNullish(value, fallbackValue) {
  if (isNullish(value)) return fallbackValue
  if (value && isAtomic(value)) return value

  return Object.entries(fallbackValue).reduce(
    (result, [key, fallbackValue]) => ({
      ...result,
      [key]: deepMergeValueIfNullish(result[key], fallbackValue)
    }),
    value
  )
}

function isAtomic(value) {
  const isPlainObject = value && value.constructor === Object
  return !isPlainObject || (value._type && value._type !== 'seo')
}

function isNullish(value) {
  return typeof value === 'undefined' || value === null
}
